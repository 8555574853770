import React, { useState } from "react";
import VerticalTile from "../../shared/verticalTile";
import members from "./data/team";
import year2 from "./data/year2";
import caret from "../../../assets/img/icons/icon-caret.png";

export default function People() {
  const [showOld, setShowOld] = useState(false);

  const toggle = () => {
    setShowOld(!showOld);
  }

  return (
    <section id="people">
      <div className="grid grid-gap-0 p-grid-standard headline-1" style={{paddingBottom: 0}}><span>2. Jahrgang</span></div>
      <div className={`grid grid-gap-0 p-grid-standard content ic-40 xl:ic-80`} style={{paddingTop: 0}}>
        {year2.map((member, index) => (
          <div className="col-12 sm:col-12 md:col-6 xl:col-3">
            <div className="grid xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100">
                <div className="col-12">
                    <div className="imageButton">
                        <img className="image" src={member.pic} alt="kontakt"></img>
                    </div>
                    <p className="body-1 py-10 fw-bold">{member.name} </p>
                    <p className={`body-2 py-10 ${member.className}`}>{member.title}</p>
                    
                    <div className="py-10">
                        <p
                        className="body-1"
                        >
                        <strong>Branche: </strong>{member.shortDescription}
                        </p>
                        <p
                        className="body-1 py-10"
                        >
                        <strong>Regionen: </strong>{member.regions}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        ))}
      </div>

      <div className="grid grid-gap-0 p-grid-standard headline-1"  style={{paddingBottom: "25px", cursor: "pointer"}} onClick={toggle}><span className={!showOld ? "accordion" : "accordion-closed"}>1. Jahrgang</span></div>
      <div className={`grid grid-gap-0 p-grid-standard content ic-40 xl:ic-80 ${!showOld ? "people-hidden" : ""}`}  style={{paddingTop: 0}}>
        {members.map((member, index) => (
          <div className="col-12 sm:col-12 md:col-6 xl:col-3">
            <div className="grid xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100">
                <div className="col-12">
                    <div className="imageButton">
                        <img className="image" src={member.pic} alt="kontakt"></img>
                    </div>
                    <p className="body-1 py-10 fw-bold">{member.name} </p>
                    <p className={`body-2 py-10 ${member.className}`}>{member.title}</p>
                    
                    <div className="py-10">
                        <p
                        className="body-1"
                        >
                        <strong>Branche: </strong>{member.shortDescription}
                        </p>
                        <p
                        className="body-1 py-10"
                        >
                        <strong>Regionen: </strong>{member.regions}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        ))}
      </div>
    </section>
  );
}