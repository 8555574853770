import React from "react";
import DefautLayout from "../layouts/default";
import * as NetworkPage from "../components/screens/network";
import "../assets/sass/index.scss";

export default function Network() {
  return (
    <DefautLayout title="Nextgen Netzwerk">
      <div id="network">
        <NetworkPage.Header />
        <NetworkPage.Introduction />
        <NetworkPage.People />
        <NetworkPage.BecomeNextgen />
      </div>
    </DefautLayout>
  );
}
