import React from "react";
import SplitText from "../../shared/splitText";

import header from "../../../assets/img/components/header/Network.webp";

export default function Header() {
  return (
    <section id="header">
      <div className="content">
        <div className="blue-hero">
          <img className="blue-hero-image" src={header} alt=""></img>
          <div className={`blue-hero-text tc-white blue-hero-text-right display-1 p-standard tt-uppercase t-right`}>
            <SplitText title={"NextGen\nNetzwerk"} />
          </div>
        </div>
      </div>
    </section>
  );
}
