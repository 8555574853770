import Adler from "../../../../assets/img/components/network/year2/Adler.jpg";
import Aumueller from "../../../../assets/img/components/network/year2/Aumueller.jpg";
import Baumann from "../../../../assets/img/components/network/year2/Baumann.jpg";
import Birmann from "../../../../assets/img/components/network/year2/Birmann.jpg";
import Bischoff from "../../../../assets/img/components/network/year2/Bischoff.jpg";
import Bregenzer from "../../../../assets/img/components/network/year2/Bregenzer.jpg";
import Bruckbauer from "../../../../assets/img/components/network/year2/Bruckbauer.jpg";
import Denegri from "../../../../assets/img/components/network/year2/Denegri.jpg";
import Doerfler from "../../../../assets/img/components/network/year2/Doerfler.jpg";
import Eckert from "../../../../assets/img/components/network/year2/Eckert.jpg";
import Frankowski from "../../../../assets/img/components/network/year2/Frankowski.jpg";
import Gallrapp from "../../../../assets/img/components/network/year2/Gallrapp.jpg";
import Hauk from "../../../../assets/img/components/network/year2/Hauk.jpg";
import Hemel from "../../../../assets/img/components/network/year2/Hemel.jpg";
import Henkel from "../../../../assets/img/components/network/year2/Henkel.jpg";
import Hierl from "../../../../assets/img/components/network/year2/Hierl.jpg";
import Hofmann from "../../../../assets/img/components/network/year2/Hofmann.jpg";
import Huebner from "../../../../assets/img/components/network/year2/Huebner.jpg";
import Kamm from "../../../../assets/img/components/network/year2/Kamm.jpg";
import Kappelsberger from "../../../../assets/img/components/network/year2/Kappelsberger.jpg";
import Kleine from "../../../../assets/img/components/network/year2/Kleine.jpg";
import Kuehl from "../../../../assets/img/components/network/year2/Kuehl.jpg";
import Meier from "../../../../assets/img/components/network/year2/Meier.png";
import Mersch from "../../../../assets/img/components/network/year2/Mersch.jpg";
import Mueller from "../../../../assets/img/components/network/year2/Mueller.jpg";
import Nuessler from "../../../../assets/img/components/network/year2/Nuessler.jpg";
import Paulus from "../../../../assets/img/components/network/year2/Paulus.jpg";
import Poehlmann from "../../../../assets/img/components/network/year2/Poehlmann.jpg";
import Schnitzer from "../../../../assets/img/components/network/year2/Schnitzer.jpg";
import Schuster from "../../../../assets/img/components/network/year2/Schuster.jpg";
import Sieve from "../../../../assets/img/components/network/year2/Sieve.jpg";
import Speckner from "../../../../assets/img/components/network/year2/Speckner.jpg";
import Stensitzki from "../../../../assets/img/components/network/year2/Stensitzki.jpg";
import Winter from "../../../../assets/img/components/network/year2/Winter.jpg";
import Wittenzellner from "../../../../assets/img/components/network/year2/Wittenzellner.jpg";
import Wolf from "../../../../assets/img/components/network/year2/Wolf.jpg";
import Zehnder from "../../../../assets/img/components/network/year2/Zehnder.jpg";
import Zollner from "../../../../assets/img/components/network/year2/Zollner-Eller.jpg";

let year2 = [
    {
        name: "Sophia Adler",
        title: "Adler Parkett",
        shortDescription: `Holzbranche`,
        regions: "Dirlewang, Schwaben",
        pic: Adler,
    }, 
    {
        name: "Maria Aumüller",
        title: "Aumüller Druck GmbH & Co. KG",
        shortDescription: `Druckindustrie`,
        regions: "Regensburg, Oberpfalz",
        pic: Aumueller,
    }, 
    {
        name: "Caroline Baumann",
        title: "Scheurich Keramik",
        shortDescription: `Konsumgüter`,
        regions: "Kleinheuchbach, Unterfranken",
        pic: Baumann,
    }, 
    {
        name: "Maximilian Birmann",
        title: "Heibi-Metall Birmann GmbH",
        shortDescription: `Metallindustrie`,
        regions: "Schopfloch, Mittelfranken",
        pic: Birmann,
    }, 
    {
        name: "Lennart Bischoff",
        title: "Inge's Christmas Decor GmbH",
        shortDescription: `Handel`,
        regions: "Neustadt bei Coburg, Oberfranken",
        pic: Bischoff,
    }, 
    {
        name: "Max Bregenzer",
        title: "MAXL Bäck GmbH & Co. KG",
        shortDescription: `Lebensmittel & Ernährung`,
        regions: "Zellingen, Unterfranken",
        pic: Bregenzer,
    }, 
    {
        name: "Valentina Bruckbauer",
        title: "Josef Bruckbauer GmbH & Co. KG",
        shortDescription: `Bau Nebengewerbe`,
        regions: "Cham, Oberpfalz",
        pic: Bruckbauer,
    }, 
    {
        name: "Sebastian Denegri Calviño",
        title: "INDUSTRIAL ELECTRONIC - INDEL GmbH & Co. KG",
        shortDescription: `Handel`,
        regions: "Kronach, Oberfranken",
        pic: Denegri,
    }, 
    {
        name: "Felix Dörfler",
        title: "Dörfler&Schmidt Präzisionsfinish GmbH",
        shortDescription: `Automobilindustrie`,
        regions: "Kammerstein, Mittelfranken",
        pic: Doerfler,
    },  
    {
        name: "Andreas Eckert",
        title: "GS SCHENK Firmengruppe",
        shortDescription: `Bau`,
        regions: "Fürth, Mittelfranken",
        pic: Eckert,
    },  
    {
        name: "Jan Frankowski",
        title: "KONTEXT public relations GmbH",
        shortDescription: `Werbung & Marketing`,
        regions: "Fürth, Mittelfranken",
        pic: Frankowski,
    },  
    {
        name: "Tina Gallrapp",
        title: "Nacharbeit Gallrapp GmbH",
        shortDescription: `Dienstleistungen`,
        regions: "Mengkofen, Niederbayern",
        pic: Gallrapp,
    },  
    {
        name: "Rainer Hauk",
        title: "Hauk Modell- u. Formenbau GmbH",
        shortDescription: `Handwerk`,
        regions: "Landsberg am Lech, Oberbayern",
        pic: Hauk,
    },  
    {
        name: "Stefan Hemel",
        title: "ROGG Verbandstoffe",
        shortDescription: `Pharma & Gesundheit`,
        regions: "Fahrenzhausen, Oberbayern",
        pic: Hemel,
    },  
    {
        name: "Susanne Henkel",
        title: "SAE Applications for Digitalization GmbH",
        shortDescription: `IT`,
        regions: "Weng, Niederbayern",
        pic: Henkel,
    },   
    {
        name: "Paul Hierl",
        title: "TR PLAST Group",
        shortDescription: `Kunststoffspritzguss`,
        regions: "Neumarkt, Oberpfalz",
        pic: Hierl,
    },  
    {
        name: "Jörg Hofmann",
        title: "HSM Stahl-und Metallhandel GmbH",
        shortDescription: `Handel&Dienstleistung`,
        regions: "Georgensgmünd, Mittelfranken",
        pic: Hofmann,
    },  
    {
        name: "Michael Hübner",
        title: "Gerald Hübner GmbH",
        shortDescription: `Handwerk`,
        regions: "Lohr am Main, Unterfranken",
        pic: Huebner,
    }, 
    {
        name: "Veronika Kamm",
        title: "Huber Technik GmbH & Co. KG",
        shortDescription: `Maschinenbau und Gummi-Industrie`,
        regions: "Erding, Oberbayern",
        pic: Kamm,
    }, 
    {
        name: "Lukas Kappelsberger",
        title: "Europatent GmbH",
        shortDescription: `Legal Tech`,
        regions: "München, Oberbayern",
        pic: Kappelsberger,
    },  
    {
        name: "Evelyn Kleine",
        title: "GOK Regler und Armaturen GmbH & Co KG",
        shortDescription: `Industrie`,
        regions: "Marktbreit, Unterfranken",
        pic: Kleine,
    },
    {
        name: "Franziska Kühl",
        title: "URT Umwelt- und Recyclingtechnik GmbH",
        shortDescription: `Energie & Umwelt`,
        regions: "Karlstadt, Unterfranken",
        pic: Kuehl,
    },  
    {
        name: "Sebastian Meier",
        title: "Weigl Nfz Service GmbH",
        shortDescription: `Handel & Dienstleistung`,
        regions: "Langenzenn, Mittelfranken",
        pic: Meier,
    }, 
    {
        name: "Miriam Mersch",
        title: "Beschäftigungs- und Qualifizierungsgesellschaft ELAN GmbH",
        shortDescription: `Gesellschaft`,
        regions: "Oberasbach, Mittelfranken",
        pic: Mersch,
    },  
    {
        name: "Ann-Kathrin Müller",
        title: "ASC Technologies AG",
        shortDescription: `Technik & Telekommunikation`,
        regions: "Hösbach, Unterfranken",
        pic: Mueller,
    },  
    {
        name: "Kerstin Nüßler",
        title: "Nüßler AG",
        shortDescription: `Handwerk`,
        regions: "Moos, Niederbayern",
        pic: Nuessler,
    }, 
    {
        name: "Stefan Paulus",
        title: "Injoy Weiden",
        shortDescription: `Freizeit`,
        regions: "Weiden, Oberpfalz",
        pic: Paulus,
    },
    {
        name: "Max Pöhlmann",
        title: "Augustin GmbH",
        shortDescription: `Handwerk`,
        regions: "Gunzenhausen, Mittelfranken",
        pic: Poehlmann,
    },  
    {
        name: "Simon Schnitzer",
        title: "Schnitzer GmbH & Co. KG",
        shortDescription: `Handwerk`,
        regions: "Hüttenkofen, Niederbayern",
        pic: Schnitzer,
    },  
    {
        name: "Jonas Schuster",
        title: "Schuster Hoteleinrichtungen GmbH",
        shortDescription: `Handwerk`,
        regions: "Bernau am Chiemsee, Oberbayern",
        pic: Schuster,
    },  
    {
        name: "Daniel Sieve",
        title: "INTEC Industrie-Technik",
        shortDescription: `Dienstleistungen`,
        regions: "München, Oberbayern",
        pic: Sieve,
    }, 
    {
        name: "Marco Speckner",
        title: "Weber Transport GmbH & Co. KG",
        shortDescription: `Verkehr & Logistik`,
        regions: "Weiherhammer, Oberpfalz",
        pic: Speckner,
    }, 
    {
        name: "Eva Stensitzki",
        title: "Gustav Klein GmbH & Co. KG",
        shortDescription: `Elektronik`,
        regions: "Schongau, Oberbayern",
        pic: Stensitzki,
    }, 
    {
        name: "Ferdinand Winter",
        title: "Trips GmbH",
        shortDescription: `Automatisierung und Digitalisierung`,
        regions: "Grafenrheinfeld, Unterfranken",
        pic: Winter,
    }, 
    {
        name: "David Wittenzellner",
        title: "Anton Wittenzellner KG",
        shortDescription: `Handel`,
        regions: "Patersdorf, Niederbayern",
        pic: Wittenzellner,
    }, 
    {
        name: "Pia Wolf",
        title: "Wolf Agrarhandel GmbH & Co. KG",
        shortDescription: `Handel`,
        regions: "Gerolzhofen, Unterfranken",
        pic: Wolf,
    }, 
    {
        name: "Klara Zehnder",
        title: "Romantik Hotel & Weingut Zehntkeller",
        shortDescription: `Tourismus & Gastronomie`,
        regions: "Iphofen, Unterfranken",
        pic: Zehnder,
    }, 
    {
        name: "Lilo Zollner-Eller",
        title: "ZOLLNER Objekttextil GmbH",
        shortDescription: `Textil`,
        regions: "Vilsbiburg, Niederbayern",
        pic: Zollner,
    },  
];

export default year2;