import Amann from "../../../../assets/img/components/network/Amann.webp";
import Apfel from "../../../../assets/img/components/network/Apfel.webp";
import Arbter from "../../../../assets/img/components/network/Arbter-Hubrich.webp";
import Berger from "../../../../assets/img/components/network/Berger.webp";
import BormannMichael from "../../../../assets/img/components/network/Bormann_Michael.webp";
import BormannTim from "../../../../assets/img/components/network/Bormann_Tim.webp";
import Daxenberger from "../../../../assets/img/components/network/Daxenberger.webp";
import Dreyer from "../../../../assets/img/components/network/dreyer.png";
import Gruber from "../../../../assets/img/components/network/Gruber.png";
import Haub from "../../../../assets/img/components/network/Haub.webp";
import Heitzer from "../../../../assets/img/components/network/Heitzer.webp";
import Heller from "../../../../assets/img/components/network/Heller.webp";
import Hey from "../../../../assets/img/components/network/Hey.webp";
import Horsch from "../../../../assets/img/components/network/Horsch.webp";
import Huber from "../../../../assets/img/components/network/Huber.png";
import Kaddick from "../../../../assets/img/components/network/Kaddick_Theresa.webp";
import Kress from "../../../../assets/img/components/network/Kress.png";
import Liebl from "../../../../assets/img/components/network/Liebl.webp";
import Pöppel from "../../../../assets/img/components/network/Pöppel.webp";
import Rank from "../../../../assets/img/components/network/Rank.webp";
import Rau from "../../../../assets/img/components/network/Rau.webp";
import Ruscheinsky from "../../../../assets/img/components/network/Ruscheinsky.png";
import Schramm from "../../../../assets/img/components/network/Schramm.webp";
import Schüle from "../../../../assets/img/components/network/Schüle.webp";
import Stöger from "../../../../assets/img/components/network/Stöger.webp";
import Walk from "../../../../assets/img/components/network/Walk.png";
import Weihrauch from "../../../../assets/img/components/network/Weihrauch.webp";
import Weinhut from "../../../../assets/img/components/network/Weinhut.webp";
import Wies from "../../../../assets/img/components/network/Wies.webp";
import Zettl from "../../../../assets/img/components/network/Zettl.webp";


let members = [
    {
        name: "Matthias Amann",
        title: "imbus AG",
        shortDescription: `IT, SW-Qualität`,
        regions: "Möhrendorf, Mittelfranken",
        pic: Amann,
    }, 
    {
        name: "Alexander Apfel",
        title: "ONLINE Schreibgeräte GmbH",
        shortDescription: `Papier, Büro, Schreibwaren`,
        regions: "Neumarkt, Oberpfalz",
        pic: Apfel,
    }, 
    {
        name: "Monika Arbter-Hubrich",
        title: "Samhammer AG",
        shortDescription: `Dienstleistung`,
        regions: "Weiden, Oberpfalz",
        pic: Arbter,
    }, 
    {
        name: "Robin Berger",
        title: "Berger Holding GmbH & Co KG",
        shortDescription: `Produktion / Zerspanung`,
        regions: "Memmingen, Schwaben",
        pic: Berger,
    }, 
    {
        name: "Michael Bormann",
        title: "Bormann EDV + Zubehör GmbH",
        shortDescription: `IT`,
        regions: "Neusäß, Schwaben",
        pic: BormannMichael,
    }, 
    {
        name: "Tim Bormann",
        title: "Bormann EDV + Zubehör GmbH",
        shortDescription: `IT`,
        regions: "Neusäß, Schwaben",
        pic: BormannTim,
    }, 
    {
        name: "Magdalena Daxenberger",
        title: "DH electronics GmbH",
        shortDescription: `Elektronik, Embedded Systems`,
        regions: "Bergen, Oberbayern",
        pic: Daxenberger,
    }, 
    {
        name: "Philipp Dreyer",
        title: "AMAZONEN-Werke",
        shortDescription: `Landtechnik`,
        regions: "Bad Windsheim, Mittelfranken",
        pic: Dreyer,
    }, 
    {
        name: "Sophie Gruber",
        title: "GRUMA Nutzfahrzeuge GmbH",
        shortDescription: `Handel und Service Fahrzeugtechnik`,
        regions: "Friedberg, Schwaben",
        pic: Gruber,
    },  
    {
        name: "Anna-Sophia Haub",
        title: "Tengelmann Twenty-One KG",
        shortDescription: `Family Equity`,
        regions: "München, Oberbayern",
        pic: Haub,
    },  
    {
        name: "Clara Heitzer",
        title: "J. Röhrl GmbH & Co KG",
        shortDescription: `Elektro-, Kabel-, Leitungsbau`,
        regions: "Schierling, Oberpfalz",
        pic: Heitzer,
    },  
    {
        name: "Lukas Heller",
        title: "Schüller Möbelwerk KG",
        shortDescription: `Küchenmöbelindustrie`,
        regions: "Herrieden, Mittelfranken",
        pic: Heller,
    },  
    {
        name: "Agnes Hey",
        title: "Richard Pflaum Verlag GmbH & Co KG",
        shortDescription: `Medienbranche`,
        regions: "München, Oberbayern",
        pic: Hey,
    },  
    {
        name: "Frederic Horsch",
        title: "Horsch Maschinen GmbH",
        shortDescription: `Landwirtschaft`,
        regions: "Schwandorf, Oberpfalz",
        pic: Horsch,
    },  
    {
        name: "Gisela Huber",
        title: "Schalt-Technik Huber GmbH",
        shortDescription: `Industrie - Elektrohandwerk`,
        regions: "München, Oberbayern",
        pic: Huber,
    },   
    {
        name: "Theresa Kaddick",
        title: "EndoLab GmbH",
        shortDescription: `Medizintechnik`,
        regions: "Riedering, Oberbayern",
        pic: Kaddick,
    },  
    {
        name: "Felix Kress",
        title: "Bergader Privatkäserei GmbH",
        shortDescription: `Lebensmittel`,
        regions: "Waging a. See, Oberbayern",
        pic: Kress,
    },  
    {
        name: "Stefan Liebl",
        title: "Liebl Gartenbau Vetriebs & Handels GmbH",
        shortDescription: `Pflanzengroßhandel`,
        regions: "Krumbach, Schwaben",
        pic: Liebl,
    }, 
    {
        name: "Susanne Pöppel",
        title: "Pöppel Abfallwirtschaft und Städtereinigung GmbH",
        shortDescription: `Entsorgung`,
        regions: "Kelheim, Niederbayern",
        pic: Pöppel,
    }, 
    {
        name: "Julia Rank",
        title: "Richard Rank GmbH & Co KG",
        shortDescription: `Baubranche`,
        regions: "Weiden, Oberpfalz",
        pic: Rank,
    },  
    {
        name: "Frederik Rau",
        title: "Rau GmbH",
        shortDescription: `Messgeräte / Werkzeughersteller`,
        regions: "Meitingen, Schwaben",
        pic: Rau,
    },
    {
        name: "Roxanne Ruscheinsky",
        title: "24-Autobahn-Raststätten GmbH",
        shortDescription: `Immobilien`,
        regions: "Regensburg, Oberpfalz",
        pic: Ruscheinsky,
    },  
    {
        name: "Lennard Schramm",
        title: "organic veggie food GmbH",
        shortDescription: `Lebensmittel - Bereich Bio/Vegan`,
        regions: "Bad Endorf, Oberbayern",
        pic: Schramm,
    },  
    {
        name: "Sebastian Schüle",
        title: "Schüle's Gesundheitsresort & SPA",
        shortDescription: `Hotellerie`,
        regions: "Oberstdorf, Schwaben",
        pic: Schüle,
    },  
    {
        name: "Stefan Stöger",
        title: "STÖGER AUTOMATION GmbH",
        shortDescription: `Maschinenbau`,
        regions: "Königsdorf, Oberbayern",
        pic: Stöger,
    }, 
    {
        name: "Michael Walk",
        title: "Metzgerei Boneberger GmbH",
        shortDescription: `Lebensmittelproduktion`,
        regions: "Schongau, Oberbayern",
        pic: Walk,
    },
    {
        name: "Felix Weihrauch",
        title: "Weihrauch & Weihrauch Sport GmbH & Co. KG",
        shortDescription: `Metallverarbeitende Industrie`,
        regions: "Mellrichstadt, Unterfranken",
        pic: Weihrauch,
    },  
    {
        name: "Andreas Weinhut",
        title: "Weinhut GmbH",
        shortDescription: `Transportbranche`,
        regions: "Neutraubling, Oberpfalz",
        pic: Weinhut,
    },  
    {
        name: "Maximilian Wies",
        title: "Wies Faszinatour",
        shortDescription: `Mobilität`,
        regions: "Weiden, Oberpfalz",
        pic: Wies,
    },  
    {
        name: "Matthias Zettl",
        title: "Zettl Group",
        shortDescription: `Automobil & IT`,
        regions: "Weng, Niederbayern",
        pic: Zettl,
    },  
];

export default members;